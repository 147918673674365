<template>
  <div class="flex min-h-screen flex-col">
    <header class="flex flex-row-reverse p-4">
      <ui-i18n-language-switch />
    </header>

    <main class="container flex flex-1 flex-col">
      <slot />
    </main>

    <footer class="flex flex-col items-center justify-center bg-slate-100 p-6">
      <p class="contact">
        <a class="email" href="mailto:samband@leiguskjol.is">
          samband@leiguskjol.is
        </a>
        -
        <a class="tel" href="tel:003545191518">5191518</a>
      </p>
      <p class="trademark">
        Igloo samningar &copy; {{ year }}. Þróað af Leiguskjól ehf.
      </p>
    </footer>
  </div>
</template>

<script setup>
const year = new Date().getFullYear()
</script>

<style scoped>
.contact {
  margin-bottom: 0;
}
.email {
  margin-right: 5px;
}
.tel {
  margin-left: 5px;
}
.trademark {
  margin-bottom: 0;
}
</style>
